import React, { FC } from 'react';
import { graphql } from 'gatsby';
import slugify from '../../utils/slugify';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Posts from '../../components/Posts';

interface CategoryProps {
  data: {
    allMarkdownRemark: any;
  };
  pageContext: {
    category: string;
  };
}

const category: FC<CategoryProps> = ({ data, pageContext }) => {
  return (
    <Layout>
      <SEO
        title={`Category: ${pageContext.category}`}
        slug={`${slugify(pageContext.category)}`}
      />
      <Posts posts={data.allMarkdownRemark.edges} />
    </Layout>
  );
};

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          html
          excerpt
          frontmatter {
            title
            slug
            tags
            date
            category
          }
        }
      }
    }
  }
`;
export default category;
